import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";
import { formatDateWithoutTime } from "../../utils";

function LastAssignedDate() {
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  const [lastAssignDate, setLastAssignDate] = useState(null);
  const [lastAssignDates, setLastAssignDates] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );

      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchLastAssignDate = async () => {
    const token = await getCurrentToken();
    try {
      const userRole = roles.includes("Reviewer") ? "Reviewer" : "Other";
      const userIdsParam =
        userRole === "Reviewer"
          ? userId
          : filterCreatedBy || selectedUser?.value || "";
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/assign-date`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: userIdsParam,
          },
        }
      );

      const data = response.data;
      setLastAssignDates(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")) {
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchLastAssignDate();
  }, [projectId, selectedUser, filterCreatedBy]);
  return (
    <div>
        <>
      {roles.includes("ProjectAdmin") && (
        <>
          <div className="mb-2">
            <Select
              options={users.map((user) => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              }))}
              value={selectedUsers}
              isClearable
              isMulti
              placeholder="Select User"
              className=""
              onChange={(selectedOptions) => {
                setSelectedUsers(selectedOptions || []);
                setFilterCreatedBy(
                  selectedOptions
                    ? selectedOptions.map((option) => option.value).join(",")
                    : ""
                );
              }}
            />
          </div>

          <ul className="list-unstyled mb-0 date-list">
            {lastAssignDates.length > 0 ? (
              lastAssignDates.map((item) => (
                <li key={item.userId} className="mb-2 h6">
                  <div className="text-muted">{item.user}</div>
                  <h6 className="fw-bold">
                    {formatDateWithoutTime(item.assignedDate)}
                  </h6>
                </li>
              ))
            ) : (
              <li>-</li>
            )}
          </ul>
        </>
      )}
      {roles.includes("Reviewer") && (
        <h4 className="fw-bold text-dark mb-0">
          {lastAssignDates.length > 0
            ? formatDateWithoutTime(lastAssignDates[0].assignedDate)
            : "-"}
        </h4>
      )}
      </>
    </div>
  );
}

export default LastAssignedDate;
