import React, { useState, useEffect, useContext } from "react";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";
import { FileEarmarkBinary } from "react-bootstrap-icons";

function IssuesPerFile() {
  const [isLoading, setIsLoading] = useState(false);
  const { tenantId, groupId, userId, projectId } = useContext(ProjectContext);
  const [IssuesPerFile, setIssuesPerFile] = useState(0);
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 7);

  const [startDate, setStartDate] = useState(
    oneWeekAgo.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    currentDate.toISOString().split("T")[0]
  );

  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/issue-per-file`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userId: userId,
            projectId: projectId,
            startDate: `${startDate} 00:00:00`,
            endDate: `${endDate} 00:00:00`,
          },
        }
      );

      const data = response.data;
      setIssuesPerFile(data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, projectId]);

  return (
    <div>
      <div className="row g-2">
        <div className="col-md-6">
          <input
            type="date"
            className="form-control mb-2"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <input
            type="date"
            className="form-control mb-2"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      <div className="bg-white d-flex">
        <div className="icon-bx">
          <FileEarmarkBinary size={20} />
        </div>
        <div className="box-content">
          <p className="fw-bold mb-0">Number of Issues Per File</p>
          <h4 className="mb-0 text-dark fw-bold">
            {IssuesPerFile.IssuePerFileCount}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default IssuesPerFile;
