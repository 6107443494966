import React, { useEffect, useState, useContext } from "react";
import ProjectContext from "../ProjectContext";
import axios from "axios";
import { getCurrentToken } from "../services/cognito/cognitoAuth";
import "bootstrap-daterangepicker/daterangepicker.css";
import Select from "react-select";
import Pagination from "../components/Pagination";
import { formatDateWithoutTime } from "../utils";
import FileCountTitleChart from "../components/dashboard/FileCountTitleChart";
import IssueCountTitleChart from "../components/dashboard/IssueCountTitleChart";
import IssueNoissueChart from "../components/dashboard/IssueNoissueChart";
import IssueNoissueCountChart from "../components/dashboard/IssueNoissueCountChart";
import ViewedNotReviewed from "../components/dashboard/ViewedNotReviewed";
import IssuesPerFile from "../components/dashboard/IssuesPerFile";
import FilesReviewCount from "../components/dashboard/FilestoReviewCount";
import { Calendar2Check, Diagram3 } from "react-bootstrap-icons";
import TotalReviewsAssignees from "../components/dashboard/TotalReviewsAssignees";
import ReviewersProgressChart from "../components/dashboard/ReviewersProgressChart";
import LastAssignedDate from "../components/dashboard/LastAssignedDate";

function Dashboard() {
  const { projectId, userId, roles, tenantId, groupId } =
    useContext(ProjectContext);
  const [isLoading, setIsLoading] = useState(true);
  const [lastReviewDateCount, setLastReviewDateCount] = useState("");
  
  const fetchLastReviewDateCount = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/last-review-day-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userId: userId,
          },
        }
      );

      const data = response.data.LastReviewedDayCount;
      setLastReviewDateCount(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchLastReviewDateCount();
  }, []);

  return (
    <div className="dashboard-container">
      <h2 className="fw-bold mb-0">Dashboard</h2>
      <div className="py-3">
        {roles.includes("Reviewer") && (
          <>
            <div className="row">
              <div className="col-md-3 pb-3">
                <div className="shadow p-3 bg-white rounded h-100">
                  <IssueNoissueCountChart />
                </div>
              </div>
              <div className="col-md-9">
                <div className="row h-100">
                  <div className="col-md-6 mb-3">
                    <div className="shadow p-3 bg-white rounded h-100">
                      <FileCountTitleChart />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="shadow p-3 bg-white rounded h-100">
                      <IssueCountTitleChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="shadow p-3 bg-white rounded h-100">
                  <FilesReviewCount />
                </div>
              </div>

              <div className="col-md-8 mb-3">
                <div className="row">
                  <div className="col-md-6 pb-3">
                    <div className="shadow p-3 bg-white rounded d-flex align-items-center h-100">
                      <div className="icon-bx">
                        <Calendar2Check size={20} />
                      </div>
                      <div className="box-content">
                        <p className="fw-bold mb-0">Last Assigned Date</p>
                        <LastAssignedDate />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pb-3">
                    <div className="shadow p-3 bg-white rounded h-100 d-flex align-items-center">
                      <div className="icon-bx">
                        <Diagram3 size={20} />
                      </div>
                      <div className="box-content">
                        <p className="fw-bold mb-0">Last Reviewed Date Count</p>
                        <h4 className="mb-0 text-dark fw-bold">
                          {lastReviewDateCount}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 pb-3">
                    <div className="shadow p-3 bg-white rounded">
                      <ViewedNotReviewed />
                    </div>
                  </div>
                  <div className="col-md-6 pb-3">
                    <div className="shadow p-3 bg-white rounded">
                      <IssuesPerFile />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {roles.includes("ProjectAdmin") && (
          <>
            <div className="row">
              <div className="col-md-3 pb-3">
                <div className="shadow p-3 bg-white rounded h-100">
                  <IssueNoissueCountChart />
                </div>
              </div>
              <div className="col-md-9">
                <div className="row h-100">
                  <div className="col-md-6 mb-3">
                    <div className="shadow p-3 bg-white rounded h-100">
                      <FileCountTitleChart />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="shadow p-3 bg-white rounded h-100">
                      <IssueCountTitleChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="shadow p-3 bg-white rounded  h-100">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-primary">
                      <Calendar2Check size={22} />
                    </div>
                    <h5 className="fw-bold mb-0 ms-2">Last Assigned Date</h5>
                  </div>
                  <LastAssignedDate />
                </div>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <div className="shadow p-3 bg-white rounded">
                      <TotalReviewsAssignees />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shadow p-3 bg-white rounded">
                      <ReviewersProgressChart />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Dashboard;
