import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import Select from "react-select";
import ProjectContext from "../../ProjectContext";

function FileCountTitleChart({ title }) {
  const [chartData, setChartData] = useState(null);
  const [titles, setTitles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const typeOptions = [
    { value: "SECTION", label: "Section" },
    { value: "SUBSECTION", label: "Subsection" },
    { value: "ISSUE_NATURE", label: "Issue Nature" },
    { value: "ISSUE_TYPE", label: "Issue Type" },
    {
      value: "TEMPLATE_CHANGE_TYPE",
      label: "Template change type",
    },
    { value: "TAG", label: "Tag" },
  ];
  const [filterType, setFilterType] = useState(typeOptions[0]);
  const { tenantId, groupId, userId, projectId, roles } =
    useContext(ProjectContext);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filterCreatedBy, setFilterCreatedBy] = useState("");
  const currentDate = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 7); 

  const [startDate, setStartDate] = useState(oneWeekAgo.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(
    currentDate.toISOString().split("T")[0]
  );
  const fetchUsers = async () => {
    try {
      const token = await getCurrentToken();
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}user/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: 1,
            size: "",
            search: "",
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            group: "Reviewer",
            projectId: projectId,
            logginGroupId: groupId,
            tenantId: tenantId,
            userId: userId,
          },
        }
      );
      const users = response.data.content;

      setUsers(users);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const userRole = roles.includes("Reviewer") ? "Reviewer" : "Other";

      const userIdsParam =
        userRole === "Reviewer"
          ? userId
          : filterCreatedBy || selectedUser?.value;
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/file-count-by-title?`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: userIdsParam,
            projectId: projectId,
            title: filterType?.value || title,
            startDate: `${startDate} 00:00:00`,
            endDate: `${endDate} 00:00:00`,
          },
        }
      );

      const data = response.data;
      setChartData({
        labels: data.map((item) => item.title),
        datasets: [
          {
            label: "Count by Title",
            data: data.map((item) => item.count),
            backgroundColor: ["#4e73df"],
            borderWidth: 1,
            //barThickness: 20,
          },
        ],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (roles.includes("ProjectAdmin")){
      fetchUsers();
    }
  }, [projectId, groupId, tenantId, userId, roles]);

  useEffect(() => {
    fetchData();
  }, [
    projectId,
    filterType,
    startDate,
    endDate,
    selectedUser,
    filterCreatedBy,
  ]);
  const fetchTitles = async () => {
    setIsLoading(true);
    try {
      const token = await getCurrentToken();
      const params = {
        page: 1,
        size: "",
        sortColumn: "name",
        sortOrder: "DESC",
        projectId: projectId,
        search: "",
      };
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}issue/title-list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params,
        }
      );
      setTitles(response.data.content);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching titles:", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTitles();
  }, [projectId]);
  const colClassName = roles.includes('Reviewer') ? 'col-md-3' : 'col-md-6';
  return (
    <div>
      <h5 className="fw-bold">File Count by Title</h5>
      <div className="row g-2">
        <div className="col-md-6">
          <Select
            name="typeOptions"
            options={typeOptions}
            onChange={setFilterType}
            value={filterType}
            className=""
          />
        </div>
        {roles.includes("ProjectAdmin") && (
          <div className="col-md-6">
            <Select
              options={users.map((user) => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName}`,
              }))}
              value={selectedUser}
              isClearable
              placeholder="Select User"
              className=""
              onChange={(selectedOption) => {
                setSelectedUser(selectedOption);
                setFilterCreatedBy(selectedOption ? selectedOption.value : "");
              }}
            />
          </div>
        )}

        <div className={colClassName}>
          <input
            type="date"
            className="form-control mb-3 pe-0 ps-1"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className={colClassName}>
          <input
            type="date"
            className="form-control mb-3 pe-0 ps-1"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
      </div>
      {chartData ? (
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                  stepSize: 1, // Ensure the step size is 1 to show integer values
                  callback: function(value) {
                    if (Number.isInteger(value)) {
                      return value;
                    }
                  }
                },
                title: {
                  display: true,
                  text: "Count",
                },
                grid: {
                  display: false, 
                },
              },
              x: {
                title: {
                  display: true,
                  text: "Title",
                },
                grid: {
                  display: false, 
                },
              },
            },
          }}
        />
      ) : (
        <p className="text-center">Loading data...</p>
      )}
    </div>
  );
}

export default FileCountTitleChart;
