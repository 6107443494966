import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {
  getCurrentToken,
  getCurrentUser,
} from "../../services/cognito/cognitoAuth";

import { PencilSquare, EyeFill } from "react-bootstrap-icons";
import ProjectUsers from "./ProjectUsers";
import ProjectContext from "../../ProjectContext";
import Pagination from "../Pagination";
import { formatDate } from "../../utils";

function ProjectsListAdmin({ searchQuery, refreshKey, onEditProject }) {
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageSize, setTotalPageSize] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [cognitoSub, setCognitoSub] = useState(null);
  const { projectId, setProjectId, roles, userId, tenantId } =
    useContext(ProjectContext);
  const [message, setMessage] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };
  const pageSize = 10;
  const [totalListCount, setTotalListCount] = useState(0);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchQuery, refreshKey, refresh]);

  const fetchData = async () => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }
      if (!userId) {
        console.log("User ID is not set");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}project/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            size: pageSize,
            search: searchQuery,
            status: "",
            sortColumn: "id",
            sortOrder: "DESC",
            userId: "",
            tenantId: tenantId,
          },
        }
      );
      const jsonData = response.data;
      const pageInfo = response.data.pageInfo;
      //const totalPages = Math.ceil(pageInfo.totalElements / pageInfo.size);
      if (pageInfo.totalElements > pageInfo.size) {
        setTotalPageSize(pageInfo.totalPageSize + 1);
      } else {
        setTotalPageSize(pageInfo.totalPageSize);
      }
      const totalList = response.data.pageInfo.totalElements;
      setTotalListCount(totalList);
      if (Array.isArray(jsonData.content)) {
        setProjects(jsonData.content);
      } else {
        console.error("Invalid data format: content is not an array");
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {}, [projectId]);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusClassName = (status) => {
    return status === "ACTIVE"
      ? "badge text-bg-success"
      : "badge text-bg-secondary";
  };
  const handleShowUsers = (projectId, projectName) => {
    setSelectedProjectId(projectId);
    setSelectedProjectName(projectName);
    handleShow();
  };
  const handleProjectStatusChange = async (projectId, currentStatus) => {
    try {
      const token = await getCurrentToken();

      if (!token) {
        console.error("No token found");
        return;
      }

      const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE";

      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}project/status`,
        {
          projectId: projectId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Refresh the projects list after changing the status
      fetchData();
    } catch (err) {
      console.log("Error in handleProjectStatusChange:", err);
    }
  };
  return (
    <div className="table-responsive border rounded p-2">
      {message && (
        <div
          className="alert alert-success text-center fw-bold fs-6"
          role="alert"
        >
          {message}
        </div>
      )}
      {projects.length > 0 ? (
        <>
          <table className="table table-borderless">
            <thead>
              <tr>
                <th>Project Name</th>
                <th>Tenant Name</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Users</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    {project.name}
                    {project.id === projectId && (
                      <span className="badge text-bg-primary ms-2">
                        Current
                      </span>
                    )}
                  </td>
                  <td>{project.tenantName}</td>
                  <td>{project.createdAt ? formatDate(project.createdAt) : ""}</td>
                  <td>
                    <span className={getStatusClassName(project.status)}>
                      {project.status}
                    </span>
                  </td>
                  <td>
                    <Link
                      className="btn btn-sm btn-primary border-0 px-2 me-2"
                      onClick={() => handleShowUsers(project.id, project.name)}
                    >
                      <EyeFill size={16} />
                    </Link>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary border-0 px-2 me-2"
                      onClick={() => onEditProject(project)} 
                      disabled={project.status === "INACTIVE"}
                    >
                      <PencilSquare size={16} />
                    </button>
                    <button
                      className={`btn btn-sm ${
                        project.status === "ACTIVE"
                          ? "btn-danger"
                          : "btn-success"
                      } px-3 me-2`}
                      onClick={() =>
                        handleProjectStatusChange(project.id, project.status)
                      }
                    >
                      {project.status === "ACTIVE" ? "Deactivate" : "Activate"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Modal show={show} onHide={handleClose}>
            <ProjectUsers
              projectId={selectedProjectId}
              projectName={selectedProjectName}
            />
          </Modal>
          <Pagination
            currentPage={currentPage}
            totalPageSize={totalPageSize}
            onPageChange={handlePageChange}
            totalListCount={totalListCount}
            pageSize={pageSize}
          />
        </>
      ) : (
        <p className="text-center fs-5 mt-4">No projects to list.</p>
      )}
    </div>
  );
}

export default ProjectsListAdmin;
