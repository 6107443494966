import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2";
import axios from "axios";
import "chart.js/auto";
import { getCurrentToken } from "../../services/cognito/cognitoAuth";
import ProjectContext from "../../ProjectContext";

function FilesReviewCount() {
  const [isLoading, setIsLoading] = useState(false);
  const { tenantId, groupId, userId, projectId } = useContext(ProjectContext); 
  const [chartData, setChartData] = useState({});

  const fetchData = async () => {
    const token = await getCurrentToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}dashboard/file-review-count`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            userIds: userId,
            projectIds: projectId,
          },
        }
      );

      const data = response.data;
      console.log(data);

      // Transform the response data for the chart
      const transformedData = {
        labels: ["Assigned", "Viewed", "Reviewed"],
        datasets: [
          {
            label: "File Counts",
            data: [
              data.assignedCount[0]?.count || 0,
              data.viewedCount[0]?.fileCount || 0,
              data.reviewedCount[0]?.fileCount || 0,
            ],
            backgroundColor: ["#FF6384", "#4e73df", "#198754"],
            barThickness: 20,
          },
        ],
      };

      setChartData(transformedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [projectId]);

  return (
    <div>
      <h5 className="fw-bold">Files Review Count</h5>
      {chartData.labels ? (
        <Bar
          data={chartData}
          options={{
            indexAxis: 'y',
            scales: {
              x: {
                beginAtZero: true,
                grid: {
                  display: false, 
                },
              },
              y: {
                grid: {
                  display: false, 
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default FilesReviewCount;